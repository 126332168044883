import defaultState from './defaultState'

export default {
  setLoaded(state, payload) {
    state.isLoaded = payload
  },
  setProducts(state, payload) {
    state.products = payload
  },
  setPagination(state, meta) {
    state.pagination = meta
  },
  setCurrentPage(state, page) {
    state.pagination.current_page = page
  },
  setPerPage(state, perPage) {
    state.pagination.per_page = perPage
  },
  setCurrentSort(state, sort) {
    state.sort = sort
  },
  setProductName(state, productName) {
    state.productName = productName
  },
  setProductsFilters(state, filters) {
    state.filters = filters
  },
  reset(state) {
    Object.assign(state, defaultState())
  },
  setActiveFilters(state, payload) {
    state.activeFilters = payload
  },
  setActiveFiltersValue(state, payload) {
    state.activeFiltersValue = payload
  },
  setFiltersOptions(state, filters) {
    state.filtersOptions = filters
  },
  setFilter(state, data) {
    state.filterList = data
  },
  setProductsUsers(state, payload) {
    state.productsUsers = payload
  },
  addAdvancedOptions(state, advancedOptions) {
    state.advancedOptions = Object.entries(advancedOptions).map(([key, value]) => ({ label: value, option: key }))
  },
  setProductFilters(state, payload) {
    state.productFilters = payload
  },
  setProductColumns(state, payload) {
    state.columns = payload
  },
  setCalculations(state, payload) {
    if (!Object.keys(payload).length) {
      state.calculations = []
      return
    }

    state.calculations = payload
  },
}
