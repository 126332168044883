import httpClient from "@/httpClient"

let getProductsAbort = new AbortController()

const getProducts = (params) => {
  if (getProductsAbort) {
    getProductsAbort.abort()
  }
  getProductsAbort = new AbortController()

  return httpClient.post(
    '{currentCompanySlug}/quotes/customFields/objects/aggregations',
    params,
    { signal: getProductsAbort.signal },
  )
}

const getProductsFilters = (params) => httpClient.get(
  '{currentCompanySlug}/quotes/customFields/objects/aggregations/filters', { params },
)

const saveColumnsPosition = (columns) => httpClient.post(
  '{currentCompanySlug}/quotes/customFields/objects/aggregations/persistent-columns',
  columns,
)

const exportProductsTable = (payload) => httpClient.post(
  '{currentCompanySlug}/quotes/customFields/objects/export', payload,
)


let getViewsAbort = new AbortController()
const loadViews = (slug) => {
  if (getViewsAbort) {
    getViewsAbort.abort()
  }
  getViewsAbort = new AbortController()
  return httpClient.get(
    `{currentCompanySlug}/filter-views/recordObjects:${slug}`,
  )
}

const createFiltersView = (slug, payload) => httpClient.post(`{currentCompanySlug}/filter-views/recordObjects:${slug}`, payload)

const updateFiltersView = (slug, id, payload) => httpClient.patch(
  `{currentCompanySlug}/filter-views/recordObjects:${slug}/${id}`, payload,
)
const loadFiltersViewsCount = (slug, ids, forceCompany = null) => httpClient.get(
  `${forceCompany || '{currentCompanySlug}'}/filter-views/recordObjects:${slug}/counts`, {
    params: { ids },
  },
)

const updateFiltersViewHidden = (slug, payload) => httpClient.post(`{currentCompanySlug}/filter-views/recordObjects:${slug}/hide`, { data: payload })

const reorderFiltersView = (slug, payload) => httpClient.post(`{currentCompanySlug}/filter-views/recordObjects:${slug}/reorder`, payload)

const deleteFiltersView = (slug, filterViewId) => httpClient.delete(`{currentCompanySlug}/filter-views/recordObjects:${slug}/${filterViewId}`)

const calculateFields = (slug, payload) => httpClient.post(`{currentCompanySlug}/filter-views/recordObjects:${slug}/calculate`, payload)

export default {
  getProducts,
  getProductsFilters,
  exportProductsTable,
  saveColumnsPosition,
  loadViews,
  createFiltersView,
  updateFiltersView,
  deleteFiltersView,
  updateFiltersViewHidden,
  reorderFiltersView,
  loadFiltersViewsCount,
  calculateFields,
}
