import { files } from '@/api'
import { saveAs } from "@/utils/saveAs"

export default {
  getFileGroups: ({ commit }) => files.getFileGroups()
    .then(({ data }) => {
      commit('setFileGroups', data.data)
    }),
  createFileGroups({ commit }, payload) {
    return files.createFileGroups(payload)
      .then(({ data: { data } }) => {
        commit('addFileGroup', data)
      }).catch((err) => { throw err })
  },
  reorderFileGroups({ commit }, payload) {
    commit('setFileGroups', payload)
    return files.reorderFileGroups({ data: payload.map(({ id, sortOrder }) => ({ id, sortOrder })) })
  },
  updateFileGroups({ commit }, payload) {
    return files.updateFileGroups(payload)
      .then(({ data: { data } }) => {
        commit('updateFileGroup', data)
      }).catch((err) => { throw err })
  },
  deleteFileGroup({ commit, state }, payload) {
    return files.deleteFileGroup(payload)
      .then(() => {
        commit('setFileGroups', state.fileGroups.filter((group) => group.id !== payload.groupId))
      }).catch((err) => { throw err })
  },
  removeFile({ commit }, payload) {
    commit('preloader', true)
    return files.removeFile(payload).catch((err) => {
      commit('preloader', false)
      throw err
    }).finally(() => {
      commit('preloader', false)
    })
  },
  updateFile({ commit }, payload) {
    commit('preloader', true)
    return files.updateFile(payload)
      .catch((err) => {
        commit('preloader', false)
        throw err
      }).finally(() => {
        commit('preloader', false)
      })
  },
  requestFileSignature({ commit }, payload) {
    commit('preloader', true)
    return files.signFile(payload)
      .catch((err) => {
        commit('preloader', false)
        throw err
      }).finally(() => {
        commit('preloader', false)
      })
  },
  createEmbedLinkToSignFile({ commit }, payload) {
    commit('preloader', true)
    return files.createEmbedLinkToSignFile(payload)
      .catch((err) => {
        commit('preloader', false)
        throw err
      }).finally(() => {
        commit('preloader', false)
      })
  },
  downloadFileAction(store, { endpoint, fileType, name }) {
    return files.downloadFile({ endpoint }).then(({ data: { redirect } }) => {
      return fetch(redirect, {
        method: 'GET',
        headers: {
          Accept: fileType,
        },
      }).then((response) => {
        return response.blob()
      }).then((blob) => {
        const fileNameWithQuery = redirect?.split('/').pop()
        const fileName = decodeURI(fileNameWithQuery.split('?').shift())
        return saveAs(blob, name || fileName)
      })
    })
  },
  parseFile({ commit }, { endpoint }) {
    commit('preloader', true)
    return files.parseFile({ endpoint })
      .catch((err) => {
        commit('preloader', false)
        throw err
      }).finally(() => {
        commit('preloader', false)
      })
  },
}
