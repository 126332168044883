import axios from 'axios'
import formatFilters from '@/utils/formatFilters'
import { user, products } from '@/api'
import CUSTOM_FIELDS_TYPES from '@/constants/enums/CUSTOM_FIELDS_TYPES'
import userResponseMapper from '@/js/mappers/usersResponseMapper'

export default {
  loadProducts: function ({ commit, state, dispatch }, payload) {
    const params = {
      quoteCustomFieldSlug: payload.slug,
      page: state.pagination.current_page || 1,
      per_page: payload.per_page,
      columns: payload.columns,
      ...formatFilters.toObject(payload.filters),
      persistFilters: payload.persistFilters,
      ...(state.sort ? {
        order: state.sort.type,
        sort_by: formatFilters.toObjectFromQs(state.sort.key).slug,
        type: formatFilters.toObjectFromQs(state.sort.key).fieldType,
      } : {}),
    }
    return products.getProducts(params).then(async ({ data }) => {
      commit('setProducts', data.data)
      await dispatch('loadProductsUsers')
      commit('setPagination', data.meta)
      commit('setLoaded', false)
      return data
    }).catch((e) => {
      if (!axios.isCancel(e)) {
        console.error(e)
      }
    })
  },
  loadProductsUsers({ state, commit }) {
    const userIdsCustomFields = state.products
      .reduce((prev, curr) => ([
        ...prev,
        curr.quote.customFields,
      ]), [])
      .flat()
      .filter((field) => field.type === CUSTOM_FIELDS_TYPES.user && field.response)
      .map((field) => field.response)

    const userObjectKeys = state.products[0]?.objectConfiguration
      .filter((field) => field.type === CUSTOM_FIELDS_TYPES.account)
      .map((field) => field.name)

    const userIdsObjectFields = state.products
      .reduce((prev, curr) => ([
        ...prev,
        curr.quoteCustomFieldObject,
      ]), [])
      .map((object) => Object.entries(object)
        .filter(([key]) => userObjectKeys.includes(key))
        .map(([, value]) => value))
      .flat()

    const lastModifiedIds = state.products
      .filter((product) => product.quote.lastModifiedBy)
      .map((product) => String(product.quote.lastModifiedBy))

    const uniqueUserIds = [...new Set([...userIdsCustomFields, ...userIdsObjectFields, ...lastModifiedIds])]
    return user.getUsers( { company: localStorage.getItem('supplier_company'), params: {
      fields: 'user[id,firstName,lastName]',
      userIds: uniqueUserIds.join('|'),
      per_page: uniqueUserIds.length <= 10
        ? 10
        : uniqueUserIds.length,
      with_hidden: true,
    }}).then(({ data: { data: usersResponse } }) => {
      commit('setProductsUsers', userResponseMapper(usersResponse))
    })
  },
  loadProductsFilters({ commit }, slug) {
    return products.getProductsFilters({
      quoteCustomFieldSlug: slug,
    })
      .then(({ data }) => {
        commit('setProductsFilters', data.data)
        commit('addAdvancedOptions', data.data.filteringOptions)
        commit('setFiltersOptions', data.data.options.map((option) => {
          if (option.title.toLowerCase() === 'name') {
            return {
              ...option,
              title: 'Parent Name',
            }
          }
          return option
        }))
        return data
      })
  },
  updateProductsFilter({ commit }, dataFilter) {
    const data = Object.fromEntries(Object.entries(dataFilter).filter(([, field]) => field !== null))
    commit('setFilter', data)
  },
  calculateFields({ commit }, { slug, payload }) {
    return products.calculateFields(slug, payload)
      .then(({ data }) => {
        commit('setCalculations', data)
      }).catch((err) => {
        throw err
      })
  },
}
