export default function defaultState() {
  return {
    products: [],
    isLoaded: false,
    pagination: {},
    filters: {},
    sort: null,
    filtersSettings: {},
    activeFilters: [],
    activeFiltersValue: [],
    filterList: [],
    filtersOptions: [],
    advancedOptions: [],
    productsUsers: [],
    productFilters: {},
    columns: [],
    calculations: [],
  }
}
