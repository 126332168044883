import httpClient from "@/httpClient"

let getRecordsAbortController = new AbortController()

const getRecords = (filters, dropPrevious = false) => {
  if (dropPrevious) {
    getRecordsAbortController?.abort()
    getRecordsAbortController = new AbortController()
  }

  return httpClient.post(
    '{currentCompanySlug}/quotes/all',
    filters,
    {
      signal: getRecordsAbortController.signal,
    },
  )
}


const exportRecords = (filters) => httpClient.post(
  '{currentCompanySlug}/quotes/export',
  filters,
)

const createRecord = (company, payload, tryCount = 0) => {
  return httpClient({
    method: 'POST',
    url: `${company || '{currentCompanySlug}'}/quotes`,
    data: payload,
    headers: {
      notification: 'silent',
    },
    hideValidationSections: true,
  }).catch((e) => {
    if (e?.code === "ERR_NETWORK" && tryCount < 4) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          createRecord(company, payload, tryCount + 1)
            .then(resolve)
            .catch(reject)
        }, 5000)
      })
    }

    throw e
  })
}

export const recordVAIParse = (payload, company = '{currentCompanySlug}') => {
  return httpClient({
    method: 'POST',
    url: `${company || '{currentCompanySlug}'}/vsimple-ai/extract-document-fields`,
    data: payload,
  })
}

const editRecord = ({ id, payload }, company = '{currentCompanySlug}', ignoreEventBus = false) => httpClient.patch(`${company}/quotes/${id}`, payload, {
  validationRecordId: id,
  ignoreEventBus,
  headers: ignoreEventBus ? { notification: 'silent' } : undefined,
})

const deleteRecord = ({ id }) => httpClient.delete(`{currentCompanySlug}/quotes/${id}`)

const getRecord = (id, params, company = '{currentCompanySlug}') => httpClient.get(`${company}/quotes/${id}`, { params })

export const readRecordStatuses = (company = '{currentCompanySlug}') => httpClient.get(`${company}/quotes/statuses`)

const getRecordActivityStream = (id, params) => httpClient.get(`{currentCompanySlug}/quotes/${id}/activity-stream`, {
  params,
  ignoreGlobalNotFoundCatch: true,
})

const getRecordFiles = ({ id, group }) => httpClient.get(`{currentCompanySlug}/quotes/${id}/file-attachment`, {
  params: { group },
  ignoreGlobalNotFoundCatch: true,
})

const getRecordFileGroups = (id) => httpClient.get(`{currentCompanySlug}/quotes/${id}/files-groups`, {
  ignoreGlobalNotFoundCatch: true,
})

const createMessage = ({ id, payload }, email, tryCount = 0) => {
  return httpClient({
    method: 'PUT',
    url: `{currentCompanySlug}/quotes/${id}/activity-stream/messages`,
    data: payload,
    skipResponseInterceptor: tryCount < 4,
  }).catch((e) => {
    if (e?.code === "ERR_NETWORK" && tryCount < 4) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          createMessage({ id, payload }, email, tryCount + 1)
            .then(resolve)
            .catch(reject)
        }, 5000)
      })
    }

    throw e
  })
}

const updateMessage = ({ id, uuid, data }) => httpClient.put(
    `{currentCompanySlug}/quotes/${id}/activity-stream/messages/${uuid}`,
  {
    ...data,
  },
)

const updateRecordStatus = ({ id, status, fields }, ignoreEventBus = false) => httpClient.patch(`{currentCompanySlug}/quotes/${id}`, { status, fields }, {
  validationRecordId: id,
  ignoreEventBus,
  headers: ignoreEventBus ? { notification: 'silent' } : undefined,
})

const getFiltersStructure = () => httpClient.get('{currentCompanySlug}/quotes/filters')

const addCollaborators = ({ id, accountIds = [] }) => httpClient({
  method: 'POST',
  url: `{currentCompanySlug}/records/${id}/collaborators/add`,
  data: { accountIds },
  headers: { 'Content-Type': 'application/json', notification: 'silent' },
})

const removeCollaborators = ({ id, accountIds }) =>
  httpClient.post(`{currentCompanySlug}/records/${id}/collaborators/remove`,{ accountIds } )

const moveChildRecord = ({ fromRecordId, objectId, toRecordId }) => httpClient.post(
  `{currentCompanySlug}/quotes/${fromRecordId}/customFields/objects/${objectId}/move`,
  { recordId: toRecordId },
)

const moveChildRecordMultiple = ({ fromRecordId, objectIds, toRecordId }) => httpClient.post(
  `{currentCompanySlug}/quotes/${fromRecordId}/customFields/objects/mass-move`,
  {
    recordId: toRecordId,
    objectIds,
  },
)
const deleteObjectChild = ({ objectId, data }) => httpClient.delete(
  `{currentCompanySlug}/quotes/customFields/${objectId}/objects`,
  {
    data,
  },
)

const editObjectChild = ({ objectId, fieldId, data }) => httpClient.put(
  `{currentCompanySlug}/quotes/customFields/${objectId}/objects/${fieldId}`,
  {
    data,
  },
)

const createObjectChilds = ({ customFieldSlug, recordId, data }) => httpClient.post(
  `{currentCompanySlug}/quotes/${recordId}/customFields/${customFieldSlug}/mass-create`,
  {
    data,
  },
)

const getObjectChildren = ({ objectId }) => httpClient.get(
  `{currentCompanySlug}/quotes/customFields/${objectId}/objects`,
)

const getObjectChildrenMeta = ({ objectId }) => httpClient.get(
  `{currentCompanySlug}/quotes/customFields/${objectId}/objects/meta`,
)

const patchObjectField = ({ data, recordId }) => httpClient.put(
  `{currentCompanySlug}/quotes/customFields/objects`,
  {
    ...data,
  },
  {
    validationRecordId: recordId || null,
  },
)

const patchObjectFieldDryRun = ({ data }) => httpClient.put(
  `{currentCompanySlug}/quotes/customFields/objects?dry-run`,
  {
    data,
  },
  {
    ignoreGlobalNotify: true,
  },
)

const sortObjectFiedlds = ({ recordId, objectSlug, data }) => httpClient.put(
  `{currentCompanySlug}/quotes/${recordId}/customFields/${objectSlug}/objects/sort`,
  {
    ...data,
  },
)

const templateDocumentsList = () => httpClient.get(
  '{currentCompanySlug}/supplierCompany/template-documents',
)

const getPersistentColumns = () => httpClient.get(
  '{currentCompanySlug}/quotes/persistent-columns',
)

const savePersistentColumns = (columns) => httpClient.post(
  '{currentCompanySlug}/quotes/persistent-columns',
  {
    columns,
  },
)

const deleteFileFromRecord = (payload) => httpClient.delete(
  `{currentCompanySlug}/quotes/${payload.recordId}/file-attachment/${payload.fileId}`,
)

const restoreRecordFile = (payload) => httpClient.post(
  `{currentCompanySlug}/recycle-bin/${payload.recordId}/file/${payload.fileId}`,
)

export const loadRecordCustomFieldsConfiguration = (forceCompany = null, params = {}) => httpClient.get(
  `${forceCompany || '{currentCompanySlug}'}/records/customFields/configurations`,
  {
    params,
  },
)

const loadRecordSections = (forceCompany = null) => httpClient.get(
  `${forceCompany || '{currentCompanySlug}'}/records/customFields/sections`,
)

const createStatus = (payload) => httpClient.post('{currentCompanySlug}/quotes/statuses', payload)

const changeStatusesOrder = (payload) => httpClient.post('{currentCompanySlug}/quotes/statuses/reorder', payload)

const deleteStatus = ({ statusId, params }) => httpClient.delete(
  `{currentCompanySlug}/quotes/statuses/${statusId}`,
  {
    params,
  },
)

const updateStatus = (payload) => httpClient.put(`{currentCompanySlug}/quotes/statuses/${payload.id}`, payload)

const loadFiltersViews = () => httpClient.get('{currentCompanySlug}/filter-views/quotes')
export const loadFiltersViewsCounts = (ids, forceCompany = null) => httpClient.get(
  `${forceCompany || '{currentCompanySlug}'}/filter-views/quotes/counts`,
  {
    params: { ids },
  },
)

const createFiltersView = (payload) => httpClient.post('{currentCompanySlug}/filter-views/quotes', payload)

const reorderFiltersView = (payload) => httpClient.post('{currentCompanySlug}/filter-views/quotes/reorder', payload)

const deleteFiltersView = (filterViewId) => httpClient.delete(`{currentCompanySlug}/filter-views/quotes/${filterViewId}`)

const updateFiltersView = (payload) => httpClient.patch(
  `{currentCompanySlug}/filter-views/quotes/${payload.filterViewId}`, payload.data,
)

const updateFiltersViewHidden = (payload) => httpClient.post('{currentCompanySlug}/filter-views/quotes/hide', { data: payload })

const updateFavoriteView = (payload) => httpClient.post('{currentCompanySlug}/filter-views/quotes/favourite', { data: payload })

const getRecordCollaborators = (payload) => httpClient.get(`{currentCompanySlug}/records/${payload.recordId}/collaborators`, {
  ignoreGlobalNotFoundCatch: true,
})

const signTemplate = ({ orderId, templateId, payload }) => httpClient.post(
  `{currentCompanySlug}/quotes/${orderId}/template-document/${templateId}/e-sign`,
  payload,
)
const createEmbedLinkToSignTemplate = ({ orderId, templateId, payload }) => {
  return httpClient.post(
    `{currentCompanySlug}/quotes/${orderId}/template-document/${templateId}/e-sign/createTemplateEmbedLink`,
    payload,
  )
}

export const loadCustomFieldChangeLog = ({ recordId, customFieldSlug }, page) => {
  return httpClient.get(`{currentCompanySlug}/records/${recordId}/change-log/${customFieldSlug}`, {
    params: {
      page,
    },
  })
}

export const getSharedTableData = (recordId, linkSlug, childObjectSlug) => {
  return httpClient.get(`/{currentCompanySlug}/records/${recordId}/shared-tables/${linkSlug}/${childObjectSlug}`)
}

export const reorderSharedTable = (recordId, linkSlug, childObjectSlug, linkedRecordId, payload) => {
  return httpClient.put(
    `/{currentCompanySlug}/records/${recordId}/shared-tables/${linkSlug}/${childObjectSlug}/${linkedRecordId}/reorder`,
    payload,
  )
}

export const sortSharedTable = (recordId, linkSlug, childObjectSlug, linkedRecordId, payload) => {
  return httpClient.put(
    `/{currentCompanySlug}/records/${recordId}/shared-tables/${linkSlug}/${childObjectSlug}/${linkedRecordId}`,
    payload,
  )
}
export const updateSharedTableData = (recordId, linkSlug, payload) => {
  return httpClient.patch(`/{currentCompanySlug}/records/${recordId}/shared-tables/${linkSlug}`, payload, {
    skipDefaultForbiddenBehaviour: true,
    ignoreGlobalNotify: true,
  })
}

export const deleteSharedTableRow = (recordId, linkSlug, childObjectSlug, data) => {
  return httpClient.delete(`/{currentCompanySlug}/records/${recordId}/shared-tables/${linkSlug}/${childObjectSlug}/`, {
    data,
  })
}

export const mergeRecords = (baseRecordId, payload) => {
  return httpClient.patch(`/{currentCompanySlug}/quotes/${baseRecordId}/merge`, payload)
}

export const calculateFields = (payload) => {
  return httpClient.post(`/{currentCompanySlug}/filter-views/quotes/calculate`, payload)
}

export const restoreRecord = (recordId) => {
  return httpClient.post(`/{currentCompanySlug}/recycle-bin/${recordId}`)
}

export default {
  getRecords,
  createRecord,
  getRecord,
  deleteRecord,
  getRecordActivityStream,
  getRecordFiles,
  getRecordFileGroups,
  createMessage,
  updateMessage,
  readRecordStatuses,
  updateRecordStatus,
  addCollaborators,
  removeCollaborators,
  moveChildRecord,
  moveChildRecordMultiple,
  editRecord,
  exportRecords,
  getFiltersStructure,
  deleteObjectChild,
  editObjectChild,
  createObjectChilds,
  getObjectChildren,
  getObjectChildrenMeta,
  patchObjectField,
  sortObjectFiedlds,
  templateDocumentsList,
  getPersistentColumns,
  savePersistentColumns,
  deleteFileFromRecord,
  restoreRecordFile,
  loadRecordCustomFieldsConfiguration,
  createStatus,
  changeStatusesOrder,
  deleteStatus,
  updateStatus,
  loadFiltersViews,
  createFiltersView,
  deleteFiltersView,
  updateFiltersView,
  reorderFiltersView,
  updateFiltersViewHidden,
  updateFavoriteView,
  getRecordCollaborators,
  patchObjectFieldDryRun,
  loadRecordSections,
  signTemplate,
  loadFiltersViewsCounts,
  getSharedTableData,
  deleteSharedTableRow,
  updateSharedTableData,
  mergeRecords,
  calculateFields,
  createEmbedLinkToSignTemplate,
  restoreRecord,
}
