export default function defaultState() {
  return {
    isLoading: true,
    isLoadingMore: false,
    isExportLoading: false,
    isSaving: false,
    isRecordCollaborators: false,
    isMessageSending: false,
    isMessageUpdating: false,
    isActivityStreamLoading: true,
    isIssueCreating: false,
    errorUpdateRecord: {},
    records: [],
    filtersSettings: {},
    statuses: [],
    statusesWithDeleted: [],
    pagination: {
      current_page: 1,
      total: 0,
      per_page: 10,
    },
    sort: null,
    activeView: '',
    filterList: [],
    filters: [],
    advancedOptions: [],
    activeFilters: [],
    activeFiltersValue: [],
    templateDocumentsList: [],
    templateDocumentsListWithDeleted: [],
    record: {
      fulfillments: [],
      items: [],
      collaborators: [],
      customFields: [],
    },
    recordMeta: {
      customFieldConfiguration: [],
    },
    recordsUsers: [],
    recordUsers: [],
    persistentColumns: [],
    activityStream: [],
    recordFiles: [],
    filesRelatedUsers: [],
    statusGroups: [],
    recordFileGroups: [],
    customFieldsConfiguration: [],
    selectedFileTypes: [],
    recordsFilters: {},
    viewFilters: {},
    filterViews: [],
    viewFiltersId: -1,
    isFilterViewsLoaded: false,
    activityStreamPage: {
      currentPage: 0,
      total: 1,
    },
    sections: [],
    calculations: [],
  }
}
